var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"URM-g47bRFrXOumPNjbw6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { isProdOrStage } from './src/environment';

Sentry.init({
  dsn: 'https://796823ed36ba48fc8d4421129730c81f@o210196.ingest.sentry.io/5272229',
  tracesSampleRate: 1.0,
  enabled: isProdOrStage(),
  debug: false,
});
