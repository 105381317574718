import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const renderImageUrl = (productId, view, height, width, data, variation) => {
  let baseUrl = `/ops-api/images/${productId}/render/`;
  let viewQuery = `?view=${view ?? 'product'}`;
  let heightQuery = `${height ? `&height=${height}` : ''}`;
  let widthQuery = `${width ? `&width=${width}` : ''}`;
  let variationQuery = `${variation ? `&variation=${variation}` : ''}`;
  let dataQuery = `${
    data
      ? `&data=${typeof data === 'string' ? encodeURIComponent(data) : encodeURIComponent(JSON.stringify(data))}`
      : ''
  }`;
  return `${baseUrl}${viewQuery}${heightQuery}${widthQuery}${dataQuery}${variationQuery}`;
};

export const RenderImage = ({ productId, view, height, width, data, variation }) => {
  const [renderUrl, setRenderUrl] = useState(renderImageUrl(productId, view, height, width, data, variation));
  const [loading, setLoading] = useState(true);
  const [imageKey, setImageKey] = useState(0);

  useEffect(() => {
    updateRenderUrl();
    return () => {
      updateRenderUrl.cancel();
    };
  }, [productId, view, height, width, data, variation]);

  const updateRenderUrl = debounce(() => {
    const newRenderUrl = renderImageUrl(productId, view, height, width, data, variation);
    setLoading(true);

    const img = new Image();
    img.src = newRenderUrl;
    img.onload = () => {
      setRenderUrl(newRenderUrl);
      setLoading(false);
      setImageKey((prevKey) => prevKey + 1);
    };
    img.onerror = () => {
      setRenderUrl(newRenderUrl);
      setLoading(false);
      setImageKey((prevKey) => prevKey + 1);
    };
  }, 500);

  return (
    <div style={{ position: 'relative', display: 'inline-block', height: '100%', width: '100%' }}>
      {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress size={32} />
        </div>
      )}
      <img key={imageKey} src={renderUrl} alt="" />
    </div>
  );
};
